// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
// Components
import Head from '../components/head'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import Picture from '../components/picture'

const ProjectLink = (props) => (
  <div className={`project-link`}>
    <Link to={`/portfolio/${props.slug}`}>
      {props.thumbnail ? <Picture className="mbx2" {...props.thumbnail.fluid} /> : <Picture className="mbx2" />}
      <Markdown html={props.thumbnailText.childMarkdownRemark.html} />
    </Link>
  </div>
)

const Portfolio = ({ data, pageContext }) => {

  const homepage = data.allContentfulHomepage.edges[0].node
  const { projects } = data.allContentfulProjectPage.edges[0].node

  return (
    <Layout
      {...homepage}
      masthead={homepage.tagline}
      isHome
      hasFooter
    >
      <Head title={pageContext.siteTite} />

      <section className="portfolio max">
        {projects.map((node, i) => i < 6 && (
          <ProjectLink {...node} key={node.slug} />
        ))}
      </section>

      <section className="innerx4 container">
        <h1 className="client-wall max80">
          {homepage.clientWallSubheading && <span>{homepage.clientWallSubheading} </span>}
          {homepage.clientWall.map((tag, i) => (
            <span className="client-tag" key={tag.slug} >{tag.name}</span>
          ))}
        </h1>
      </section>

      <section className="portfolio max">
        {projects.map((node, i) => i >= 6 && (
          <ProjectLink {...node} key={node.slug} />
        ))}
      </section>
    </Layout>
  )
}

Portfolio.propTypes = {
  data: PropTypes.object.isRequired
}

export default Portfolio

export const projectsQuery = graphql`
  query portfolioQuery {
    # Project page content
    allContentfulProjectPage (
      limit: 1
    ) {
      edges {
        node {
          projects {
            title
            slug
            smallTitle {
              childMarkdownRemark {
                html
              }
            }
            thumbnail {
              fluid(maxWidth: 800, quality: 90) {
                sizes
                src
                srcSet
                aspectRatio
              }
            }
            thumbnailText {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
    # General settings content
    allContentfulHomepage (
      limit: 1
    ) {
      edges {
        node {
          tagline
          contactDetails {
            childMarkdownRemark {
              html
            }
          }
          mapLink
          email
          telephone
          projectFooterCopy {
            childMarkdownRemark {
              html
            }
          }
          # Clients
          clientWallSubheading
          clientWall {
            name
            slug
            type
          }
        }
      }
    }
  }
`
